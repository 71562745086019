<template>
  <div
      id="about"
      class="content"
      ref="aboutComponent"
  >
    <v-container
        fluid
        class="pa-0 d-flex page-container"
    >
      <v-col>
        <v-layout
            fill-height
            class="px-2 px-lg-13"
        >

          <v-col
              cols="12"
              xl="9"
          >

            <v-row
                class="ma-0"
            >
              <div class="about-title page-title">
                About
              </div>
            </v-row>

            <v-row
                class="ma-0"
            >
              <div class="about-subtitle page-subtitle">
                Funeral Rosary
              </div>
            </v-row>

            <v-row
                class="about-items ma-0"
            >
              <v-col
                  v-for="item in items" :key="item.title"
                  cols="12" md="6" sm="12"
                  class="about-item"
              >
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="description">
                  {{ item.description }}
                </div>
              </v-col>
            </v-row>

            <v-row
                class="ma-0"
            >
              <div class="about-description page-description">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when.
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when.
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when.
              </div>
            </v-row>

            <v-row
                class="ma-0"
            >
              <v-btn
                  dark
                  large
                  width="100%"
                  height="57px"
                  elevation="3"
                  color="#E18666"
                  max-width="215px"
                  @click=""
                  class="text-uppercase btn d-flex justify-space-between align-center"
              >
                <span
                    style="padding-top: 2px"
                >
                  Support Us
                </span>
                <v-img
                    max-width="26"
                    src="@/assets/Images/icons/right-vector-white.svg"
                ></v-img>
              </v-btn>
            </v-row>
          </v-col>
        </v-layout>
      </v-col>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "About.vue",
  data: () => ({
    items: [
      {
        title: 'The Website',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when.'
      },
      {
        title: 'The Foundation',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when.'
      },
      {
        title: 'The Board Members',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when.'
      },
    ],
  }),
}
</script>