<template>
  <div
      id="thankYou"
      class="content"
      ref="thankYouComponent"
  >
    <v-row
      height="100%"
      class="ma-0 d-flex fill-height"
    >
      <v-col
        class="d-flex flex-column align-center justify-center"
      >
        <div class="thank-you-title py-1">
          Thank You!
        </div>

        <div>
          <router-link
              to="/"
              class="text-decoration-none d-flex justify-center align-center link-to-home"
          >
            <v-icon
                dark
                size="20"
                class="mr-2"
            >
              mdi-arrow-left
            </v-icon>
            Back To Home.
          </router-link>
        </div>

      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ThankYou",
}
</script>