<template>
  <div
      id="faq"
      class="content"
      ref="faqComponent"
  >
    <v-container
        fluid
        class="pa-0 d-flex page-container"
    >
      <v-col>
        <v-layout
            fill-height
            class="px-2 px-lg-13"
        >

          <v-col
              cols="12"
              md="10"
          >

            <v-row
                class="ma-0"
            >
              <div class="faq-title page-title">
                FAQ
              </div>
            </v-row>

            <v-row
                class="ma-0"
            >
              <div class="faq-subtitle page-subtitle">
                Funeral Rosary
              </div>
            </v-row>

            <v-row
                class="ma-0"
            >
              <div class="faq-description faq-description-top page-description">
                {{ description }}
              </div>
            </v-row>

            <v-row
                class="panels ma-0"
            >
              <v-expansion-panels
                  accordion
                  flat
              >
                <v-expansion-panel
                    v-for="item in items"
                    class="custom-expansion-panel my-2 rounded-lg"
                >
                  <v-expansion-panel-header
                      disable-icon-rotate
                  >
                    <v-btn
                        icon
                        max-width="38px"
                        :ripple="false"
                        class="no-btn-transition doubts-icon"
                    >
                      <v-img
                          src="@/assets/Images/icons/doubts.svg"
                      ></v-img>
                    </v-btn>

                    <div
                        class="header"
                    >
                      {{ item.title }}
                    </div>

                    <template v-slot:actions>
                      <v-btn
                          icon
                          max-width="24px"
                          :ripple="false"
                          class="no-btn-transition"
                      >
                        <v-img
                            class="downVectorImg"
                            src="@/assets/Images/icons/down-vector.svg"
                        ></v-img>
                      </v-btn>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                      class="panel-content"
                  >
                    {{ item.description }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>

            <v-row
                class="ma-0"
            >
              <div class="faq-description page-description">
                {{ description }}
              </div>
            </v-row>

          </v-col>

        </v-layout>
      </v-col>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "FAQ.vue",
  data: () => ({
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
    items: [
      {
        title: 'Lorem Ipsum and one question?',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
      },
      {
        title: 'Lorem Ipsum and one question?',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
      },
      {
        title: 'Lorem Ipsum and one question?',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
      },
      {
        title: 'Lorem Ipsum and one question?',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
      },
      {
        title: 'Lorem Ipsum and one question?',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'
      },
    ],
  }),
}
</script>