<template>
  <v-form
      id="formCredit"
      ref="form"
      class="pt-2"
      v-model="valid"
      lazy-validation
  >
    <v-row
        class="mt-0"
    >
      <v-col
          cols="12"
          md="6"
          class="py-0"
      >
        <v-subheader
            class="form-label px-0"
        >
          Full Name*
        </v-subheader>
        <v-text-field
            solo
            flat
            required
            height="45px"
            v-model="fullname"
            :rules="fullNameRules"
            background-color="#E8E8E8"
            class="form-input credit-debit rounded-lg"
            placeholder="ex: John Smith"
        ></v-text-field>
      </v-col>

      <v-col
          cols="12"
          md="6"
          class="py-0"
      >
        <v-subheader
            class="form-label px-0"
        >
          E-Mail*
        </v-subheader>
        <v-text-field
            solo
            flat
            required
            single-line
            height="45px"
            v-model="email"
            :rules="emailRules"
            background-color="#E8E8E8"
            class="form-input rounded-lg"
            placeholder="ex: John@smith.com"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
          cols="12"
          md="6"
          class="py-0"
      >
        <v-subheader
            class="form-label px-0"
        >
          Phone Number
        </v-subheader>
        <v-text-field
            solo
            flat
            single-line
            height="45px"
            v-model="tel"
            :rules="tel !== '' ? telRules : []"
            background-color="#E8E8E8"
            class="form-input rounded-lg"
            placeholder="(941) 777-7777"
            v-mask="['(###) ###-####', '(###) ####-###']"
        ></v-text-field>
      </v-col>

      <v-col
          cols="12"
          md="6"
          class="py-0"
      >
        <v-subheader
            class="form-label px-0"
        >
          Zip code*
        </v-subheader>
        <v-text-field
            solo
            flat
            required
            single-line
            height="45px"
            v-model="zip"
            :rules="zipRules"
            background-color="#E8E8E8"
            class="form-input rounded-lg"
            placeholder="00000"
            v-mask="'#####'"
        ></v-text-field>
      </v-col>

    </v-row>

    <v-row
        class="mt-9 error--text"
    >
      <v-col
          cols="12"
          class="py-0"
          style="position: relative"
      >

        <v-row>
          <v-col>
            <v-subheader
                class="donation-label px-0 text-capitalize"
            >
              Credit Card Details
            </v-subheader>

            <v-row
                class="mt-0"
            >
              <v-col
                  cols="12"
                  class="py-0"
              >
                <v-subheader
                    class="form-label px-0"
                >
                  Card Number*
                </v-subheader>
                <div
                    class="form-input card stripe rounded-lg"
                    id="card-number-element"
                ></div>
              </v-col>

            </v-row>

            <v-row
                class="mt-8"
            >
              <v-col
                  cols="12"
                  class="py-0"
              >
                <v-subheader
                    class="form-label px-0"
                >
                  Cardholder Name*
                </v-subheader>
                <v-text-field
                    dense
                    solo
                    flat
                    required
                    single-line
                    height="45px"
                    elevation="0"
                    color="transparent"
                    v-model="cardholdername"
                    background-color="#FFFFFF"
                    class="form-input card rounded-lg"
                    placeholder="JOHN SMITH"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row
                class="mt-4"
            >
              <v-col
                  cols="6"
                  class="py-0"
              >
                <v-subheader
                    class="form-label px-0"
                >
                  Exp. Date*
                </v-subheader>
                <div
                    class="form-input card stripe rounded-lg"
                    id="card-expiry-element"
                ></div>
              </v-col>

              <v-col
                  cols="6"
                  class="py-0"
              >
                <v-subheader
                    class="form-label px-0"
                >
                  CVC/CVV*
                </v-subheader>
                <div
                    id="card-cvc-element"
                    class="form-input card stripe rounded-lg"
                ></div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div
            v-if="!isValidCardData()"
            class="mt-10 error-message-card"
        >
          Card is required
        </div>
      </v-col>
    </v-row>

  </v-form>

</template>

<script>
import {mask} from 'vue-the-mask'
import axios from 'axios';
import router from "../../router";

export default {
  name: 'DonationCreditOrDebitCard',
  props: ['amountType', 'amount'],
  directives: {
    mask,
  },
  data: (vm) => ({
    stripe: null,
    stripeError: '',
    stripeAPIToken: `${process.env.VUE_APP_STRIPE_PK_KEY}`,

    valid: false,

    fullname: '',
    fullNameRules: [
      v => !!v || 'Full Name is required',
      v => (v && v.length <= 30) || 'Full Name must be less than 30 characters',
    ],

    email: '',
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],

    tel: '',
    telRules: [
      v => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(v) || 'Phone number must be valid'
    ],

    zip: '',
    zipRules: [
      v => !!v || 'Zip is required',
      v => /^[0-9]{5}$/im.test(v) || 'Zip must be valid'
    ],

    cardholdername: '',
    // cardHolderNameRules: [
    //   v => /^((?:[A-Za-z]+ ?){1,3})$/im.test(v) || ''
    // ],
    card: {
      cvc: '',
      number: '',
      expiry: '',
      name: vm.cardholdername,
    },
    cardErrorMessage : '',
  }),
  mounted(){
    this.includeStripe('js.stripe.com/v3/', () => {
      this.configureStripe();
    });

    this.isValidCardData()
  },
  methods: {

    isValidCardData() {
      const cardData = this.card;
      const cardHolderName = this.cardholdername;

      return !!cardData.cvc && !!cardData.expiry && !!cardHolderName && !!cardData.number
    },

    includeStripe(URL, callback) {
      let documentTag = document, tag = 'script',
          object = documentTag.createElement(tag),
          scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = '//' + URL;
      if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },

    configureStripe() {
      this.stripe = Stripe(this.stripeAPIToken);
      this.elements = this.stripe.elements();
      this.cardNumberElement = this.elements.create("cardNumber", { placeholder: '0000 0000 0000 0000' });
      this.cardExpiryElement = this.elements.create("cardExpiry", { placeholder: 'MM/YY',  });
      this.cardCvcElement = this.elements.create("cardCvc", { placeholder: '---' });
      this.handlingStripeElementEvents();
    },

    handlingStripeElementEvents() {
      const vm = this;
      this.cardNumberElement.on('change', (event) => {
        this.cardErrorMessage = event?.error?.message;
        vm.card.number = !!event.complete;
      });
      this.cardExpiryElement.on('change', (event) => {
        this.cardErrorMessage = event?.error?.message;
        vm.card.expiry = !!event.complete;
      });
      this.cardCvcElement.on('change', (event) => {
        this.cardErrorMessage = event?.error?.message;
        vm.card.cvc = !!event.complete;
      });
      this.mountStripeElements();
    },

    mountStripeElements() {
      this.cardNumberElement.mount("#card-number-element");
      this.cardExpiryElement.mount("#card-expiry-element");
      this.cardCvcElement.mount("#card-cvc-element");
    },

    charge() {
      this.stripe.createToken(this.cardNumberElement).then((result) => {

        const data = {
          donationType: this.amountType,
          amount: this.amount,
          stripeToken: result.token.id,
          fullName: this.fullname,
          email: this.email,
          phoneNumber: this.tel,
        };
        axios.post("api/paymentWithStripe", data)
            .then(response => this.articleId = response.data.id);
            router.push('thankyou')

            .catch((res) => {
              console.log(res)
            });
      });

    }
  },
}
</script>