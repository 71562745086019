<template>
  <div
      id="home"
      class="content"
      ref="homeComponent"
      v-model="offsetHeight"
      v-resize="getBtnPosition"
  >
    <v-container
      fluid
      class="d-flex home-container px-0 px-md-3 page-container"
    >
      <v-col
        class="flex flex-column"
      >

        <v-layout
            class="fill-height px-2 px-md-6 px-lg-13"
        >
          <Slider
            ref="sliderComponent"
          >

          </Slider>
          <v-col
              class="pa-0 d-flex flex-column justify-center col-12 col-md-8 col-lg-5 col-xl-6 home-main-content"
          >
            <HomeMainComponent
                @openDialog="openDialog($event)"
                @addFreeGuideComponentShowing="changeVisibleHomeComponent($event)"
                v-if="!showFreeGuideComponent"
            ></HomeMainComponent>

            <AddFreeGuide
                @openDialog="openDialog($event)"
                v-if="showFreeGuideComponent"
            ></AddFreeGuide>

            <v-row
                class="ma-0 d-flex d-md-none"
                justify="center"
            >
              <v-img
                  contain
                  width="100%"
                  max-width="414"
                  max-height="448"
                  class="footer-img"
                  src="@/assets/Images/Home/home-background-footer.png"
              ></v-img>
            </v-row>
          </v-col>
        </v-layout>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import HomeMainComponent from "./HomeMainComponent";
import AddFreeGuide from "./AddFreeGuide";
import Slider from "./Slider";
import resize from 'vue-resize-directive'

export default {
  name: 'Home',
  directives: {
    resize,
  },
  components: {
    Slider,
    AddFreeGuide,
    HomeMainComponent,
  },
  data: () => ({
    btnPosition : {
      top: '',
      right: '',
    },
    offsetHeight: 0,
    showFreeGuideComponent: false,
  }),
  methods: {
    getBtnPosition() {
      this.offsetHeight = this.$refs.homeComponent.offsetHeight;
      this.$refs.sliderComponent.btnPositionFunc(this.btnPosition,this.offsetHeight)
    },
    changeVisibleHomeComponent(value) {
      this.showFreeGuideComponent = value;
    },
    openDialog(value) {
      this.$refs.sliderComponent.dialog = value;
    }
  },
  mounted() {
    this.getBtnPosition();
    this.$root.$on('toHome', data => {
      this.showFreeGuideComponent = data;
    });
  },
}
</script>
