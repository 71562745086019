import Vue from 'vue'
import App from './App.vue'

//ajax
import axios from 'axios'
import VueAxios from "vue-axios";

import router from './router'
import vuetify from "./plugins/vuetify";
import '@/assets/SCSS/style.scss';

//Fonts
import "@fontsource/mukta";
import "@fontsource/petrona";
import "@fontsource/montserrat"

//datetime picker
import DatetimePicker from 'vuetify-datetime-picker'
Vue.use(DatetimePicker)

//plugins

//Loader
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.component('loading-overlay', Loading)

Vue.config.productionTip = false

// Vue.use(VueAxios, axios)
Vue.prototype.$axios = axios

import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
