<template>

  <v-dialog
      v-model="dialog"
      no-click-animation
      overlay-opacity="0.5"
      overlay-color="#000000"
  >
    <v-btn
        icon
        size="27"
        color="#E0E0E0"
        class="v-btn--absolute dialog-close-btn"
        @click="dialog = false"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <template v-slot:activator="{ on, attrs }">

      <div
          :style="btnPosition"
          class="buttons-group v-btn--absolute"
      >
        <v-btn
            dark
            small
            v-on="on"
            v-bind="attrs"
            color="#EDAA93"
            class="example-button rounded-pill text-none mr-3"
        >
          See the example
        </v-btn>
        <v-btn
            fab
            dark
            x-small
            v-on="on"
            v-bind="attrs"
            color="#EDAA93"
            class="example-button"
        >
          <v-img
              max-width="18"
              max-height="18"
              src="@/assets/Images/icons/file.svg"
          ></v-img>
        </v-btn>
      </div>
    </template>
    <VueSlickCarousel
        v-bind="settings"
    >
      <v-img
          contain
          src="@/assets/Images/Home/Slider/page1-min.jpg"
      ></v-img>

      <v-img
          contain
          src="@/assets/Images/Home/Slider/page2-min.jpg"
      ></v-img>

      <template #prevArrow="arrowOption">
        <div class="custom-arrow left">
          <v-img
              contain
              src="@/assets/Images/icons/prev.svg"
          ></v-img>
        </div>
      </template>

      <template #nextArrow="arrowOption">
        <div class="custom-arrow right">
          <v-img
              contain
              src="@/assets/Images/icons/next.svg"
          ></v-img>
        </div>
      </template>
    </VueSlickCarousel>
  </v-dialog>

</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "Slider",
  components: {
    VueSlickCarousel
  },
  data: () => ({
    dialog: false,
    settings: {
      arrows: true,
    },
    btnPosition: [],
  }),
  methods: {
    btnPositionFunc(btnPosition, offsetHeight) {
      this.btnPosition = btnPosition;
      btnPosition.top = (offsetHeight - 125) / 2 + 'px';
      btnPosition.right = offsetHeight / 2 * 0.66 + 32 + 'px';
    }
  },
}
</script>