<template>
  <div
      id="donation"
      class="content"
      ref="donationComponent"
  >
    <v-container
        fluid
        class="pa-0 d-flex align-center page-container"
    >
      <v-col>

        <v-layout
            fill-height
            class="px-2 px-md-6 px-lg-13"
        >

          <v-col
              class="px-2"
          >

            <v-row
                class="ma-0"
            >
              <div class="donation-subtitle page-subtitle">
                Make a
              </div>
            </v-row>

            <v-row
                class="ma-0"
            >
              <div class="donation-title page-title">
                Donation
              </div>
            </v-row>

            <v-row
                class="ma-0"
            >
              <div class="donation-description page-description">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                when an unknown printer took a galley of type and scrambled it to make a type
                specimen book.
              </div>
            </v-row>
            <v-form
                id="form"
                ref="form"
                v-model="valid"
                lazy-validation
            >

              <v-row
                  class="ma-0"
              >
                <v-col
                    class="pa-0"
                >
                  <v-subheader
                      class="donation-label px-0 text-capitalize"
                  >
                    TYPE OF DONATION
                  </v-subheader>
                  <v-btn-toggle
                      dark
                      group
                      mandatory
                      class="buttons-group d-flex flex-wrap"
                  >
                    <v-btn
                        v-for="type in types" :key="type.regularity"
                        dark
                        width="100%"
                        height="45px"
                        max-width="143px"
                        active-class="donation-type-active-btn"
                        class="rounded-lg text-none ml-0 mr-1 mr-md-3 type-buttons donation-btn col-3"
                        @click="changeDonationType(type)"
                    >
                      {{ type.regularity }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <v-row
                  class="ma-0"
              >
                <v-col
                    class="pa-0 my-3 my-md-8"
                >
                  <v-subheader
                      class="donation-label px-0 text-capitalize"
                  >
                    AMOUNT
                  </v-subheader>
                  <v-btn-toggle
                      dark
                      group
                      mandatory
                      v-model="amount"
                      class="buttons-group d-flex flex-wrap"
                  >
                    <v-btn
                        v-for="amount in amounts[amountType - 1].donationAmount" :key="amount"
                        dark
                        :value="amount"
                        width="100%"
                        height="45px"
                        max-width="fit-content"
                        @click="otherInputValidation = false"
                        active-class="donation-type-active-btn"
                        class="rounded-lg text-none ml-0 mr-1 mr-md-3 amount-buttons donation-btn col-1"
                    >
                      <v-icon
                          size="16"
                      >
                        mdi-currency-usd
                      </v-icon>
                      {{ amount }}
                    </v-btn>
                    <v-btn
                        dark
                        height="45px"
                        :value="price"
                        max-width="93px"
                        @click="otherInputValidation = true"
                        class="rounded-lg text-none ml-0 mr-0 pa-0 mr-sm-3"
                    >
                      <v-text-field
                          solo
                          light
                          dense
                          required
                          height="45px"
                          hide-details
                          v-model="price"
                          v-money="money"
                          @input="otherAmount"
                          placeholder="Other"
                          background-color="#E8E8E8"
                          class="custom-amount rounded-lg"
                          prepend-inner-icon="mdi-currency-usd"
                          :rules="otherInputValidation ? amountRules : []"
                      >
                      </v-text-field>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <v-row
                  class="ma-0"
              >
                <v-col
                    class="pa-0"
                >
                  <v-subheader
                      class="donation-label px-0 text-capitalize"
                  >
                    CHOOSE PAYMENT METHOD
                  </v-subheader>
                  <v-radio-group
                      :rules="radioRules"
                      v-model="radioGroup"
                  >
                    <v-radio
                        color="#3E4968"
                        value="radio-1"
                        class="radio-buttons my-0 mr-1 mb-3"
                        @click="paypalMethod"
                    >
                      <template v-slot:label>
                        <v-img
                            class="ml-2"
                            max-height="26px"
                            max-width="101px"
                            src="@/assets/Images/Donation/paypal.png"
                        ></v-img>
                      </template>
                    </v-radio>
                    <v-radio
                        color="#3E4968"
                        value="radio-2"
                        class="radio-buttons my-0 mr-1"
                        @click="creditOrDebitMethod"
                    >
                      <template v-slot:label>
                        <v-row
                            class="ma-0 ml-2"
                        >
                          <div
                              class="credit-debit-card-text"
                          >
                            Credit or debit card
                          </div>
                          <v-img
                              max-height="26px"
                              max-width="191px"
                              src="@/assets/Images/Donation/cards.png"
                          ></v-img>
                        </v-row>
                      </template>

                    </v-radio>
                  </v-radio-group>

                  <v-expand-transition>
                    <v-card
                        v-show="creditOrDebitCardDisplay"
                        elevation="0"
                        class="stripe-block"
                    >
                      <DonationCreditOrDebitCard
                          ref="donationCreditOrDebitCard"
                          :amountType="amountType"
                          :amount="amount"
                      >
                      </DonationCreditOrDebitCard>
                    </v-card>
                  </v-expand-transition>
                </v-col>
              </v-row>

              <v-btn
                  dark
                  large
                  width="100%"
                  elevation="0"
                  color="transparent"
                  max-width="179px"
                  @click="donate"
                  class="mt-3 pa-0"
                  :disabled="!valid"
                  v-show='!creditOrDebitCardDisplay && isPaypal'
              >
                <div
                    ref="paypal"
                    style="width: 100%;"
                ></div>
              </v-btn>

              <v-btn
                  dark
                  large
                  width="100%"
                  height="57px"
                  elevation="3"
                  color="#E18666"
                  max-width="179px"
                  :disabled="creditOrDebitCardDisplay ?
                  (!(!!this.$refs.donationCreditOrDebitCard.$refs.form.validate() &&
                      !!this.$refs.donationCreditOrDebitCard.isValidCardData()))
                      : !valid"
                  @click="donate"
                  v-if='creditOrDebitCardDisplay'
                  class="form-btn text-capitalize btn d-flex align-center text-center mt-3"
              >
                <span
                    class="pr-6"
                >
                  Donate
                </span>
                <v-img
                    max-width="26"
                    src="@/assets/Images/icons/right-vector-white.svg"
                ></v-img>
              </v-btn>
            </v-form>

          </v-col>

        </v-layout>

      </v-col>
    </v-container>
  </div>
</template>

<script>
// import emailjs from 'emailjs-com';
import DonationCreditOrDebitCard from "./DonationCreditOrDebitCard";
import {VMoney} from 'v-money'

export default {
  name: 'Donation',
  components: {
    DonationCreditOrDebitCard
  },
  directives: {
    money: VMoney
  },
  data () {
    return {
      valid: true,

      amountType: 1,

      type: 'Monthly',
      typeRules: [
        v => !!v || 'Donation type is required',
      ],
      types: [
        {regularity: 'One time', changeAmountType: 1},
        {regularity: 'Monthly', changeAmountType: 2},
        {regularity: 'Annually', changeAmountType: 3},
      ],

      amount: 1,
      amountRules: [
        v => !!(+v) || 'Donation amount is required',
      ],
      amounts: [
        {donationAmount: ['10', '25', '50', '75', '100']},
        {donationAmount: ['10', '20', '30', '40', '50']},
        {donationAmount: []},
      ],
      otherInputValidation: false,
      price: '',
      money: {
        decimal: '.',
        thousands: '.',
        precision: 2,
        masked: false /* doesn't work with directive */
      },

      radioGroup: '',
      radioRules: [
        v => !!v || 'Payment method is required',
      ],

      loaded: false,
      paidFor: false,
      isPaypal: false,

      creditOrDebitCardDisplay: false,
    }

  },
  mounted: function () {
    const script = document.createElement("script");
    script.src =
        `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_PUBLIC_KEY}`;
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    changeDonationType(e) {
      this.amountType = e.changeAmountType;
      if (this.amountType !== 3) {
        this.amount = '10'
        this.otherInputValidation = false
      } else {
        this.otherInputValidation = true
      }
    },

    otherAmount() {
      if (this.otherInputValidation) this.amount = this.price;
    },

    paypalMethod() {
      this.creditOrDebitCardDisplay = false;
      if (this.$refs.form.validate()) {
        this.isPaypal = true;
      }
    },

    setLoaded: function () {
      this.loaded = true;
      window.paypal
          .Buttons({
            style: {
              color:'white',
              label:'paypal',
              tagline: false,
              height: 55,
              layout: 'horizontal'
            },
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: "USD",
                      value: this.amount,
                    }
                  }
                ]
              });
            },
            onApprove: async (data, actions) => {
              const order = await actions.order.capture();
              this.paidFor = true;
              await this.$router.push({name: 'ThankYou'})
              console.log(order);
            },
            onError: err => {
              console.log(err);
            }
          })
          .render(this.$refs.paypal);
    },

    creditOrDebitMethod() {
      this.creditOrDebitCardDisplay = true;
    },

    donate() {
      if (this.$refs.form.validate()) {
        console.log(this.type, this.amount)

        if (this.creditOrDebitCardDisplay) {
          this.$refs.donationCreditOrDebitCard.charge();
        }
      }
    }
  }
}
</script>
