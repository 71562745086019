<template>
  <div>
    <v-row
        class="ma-0"
    >
      <div class="home-title page-title">
        Funeral Rosary Guide
      </div>
    </v-row>

    <v-row
        class="ma-0"
    >
      <div class="home-subtitle page-subtitle">
        Create a custom Funeral Rosary Prayer guide.
      </div>
    </v-row>

    <v-row
        class="ma-0"
    >
      <div class="home-description page-description">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
        when an unknown printer took a galley of type and scrambled it to make a type specimen book.

        <br><br>
        It has survived not only five centuries, but also the leap into electronic typesetting,
        remaining essentially unchanged. It was popularised in the 1960s .
      </div>
    </v-row>

    <v-btn
        dark
        large
        width="100%"
        height="57px"
        elevation="3"
        color="#E18666"
        max-width="290"
        @click="addFreeGuideComponentShowing"
        class="text-uppercase btn d-flex justify-space-between align-center"
    >
      <span
          style="padding-top: 1px"
      >
        Create guide free
      </span>
      <v-img
          max-width="26"
          src="@/assets/Images/icons/right-vector.svg"
      ></v-img>
    </v-btn>
    <v-btn
        plain
        depressed
        max-width="124"
        color="#3E4968"
        :ripple="false"
        @click="dataOpen"
        class="example-button-md text-none text-decoration-underline pa-0 pt-2 d-none"
    >
      See the example
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "HomeMainComponent",
  data : () => ({
    addFreeGuideShowing: false,
  }),
  methods: {
    addFreeGuideComponentShowing() {
      this.addFreeGuideShowing = true;
      this.$emit('addFreeGuideComponentShowing',this.addFreeGuideShowing);
    },
    dataOpen() {
      this.$emit('openDialog',true);
    },
  },
}
</script>