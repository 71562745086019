<template>
  <div id="addFreeGuide">
    <loading-overlay
      :active="isLoading"
      :is-full-page="fullPage"
      loader="spinner"
      color="#3E4968"
    />

    <v-row class="ma-0">
      <div class="home-title page-title">Funeral Rosary Guide</div>
    </v-row>

    <v-row class="ma-0">
      <div class="home-description page-description">
        Insert the deceased person’s and the funeral information below:
      </div>
    </v-row>

    <v-row class="ma-0">
      <v-form id="form" ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="6" class="py-0">
            <v-subheader class="form-label px-0"> First Name* </v-subheader>
            <v-text-field
              solo
              required
              height="45px"
              v-model="firstname"
              :rules="firstNameRules"
              background-color="#E8E8E8"
              class="form-input rounded-lg"
              placeholder="Deceased’s person first name"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0">
            <v-subheader class="form-label px-0"> Last Name* </v-subheader>
            <v-text-field
              solo
              required
              single-line
              height="45px"
              v-model="lastname"
              :rules="lastNameRules"
              background-color="#E8E8E8"
              class="form-input rounded-lg"
              placeholder="Deceased’s person last name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" class="py-0 datetime-picker">
            <v-subheader class="form-label px-0"> Date </v-subheader>

            <v-datetime-picker
              v-model="datetime"
              :textFieldProps="textFieldProps"
              :date-picker-props="dateProps"
              :time-picker-props="timeProps"
            >
              <template slot="dateIcon">
                <v-icon color="#3E4968"> mdi-calendar </v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon color="#3E4968"> mdi-clock </v-icon>
              </template>
            </v-datetime-picker>
          </v-col>

          <v-col cols="12" md="6" class="py-0">
            <v-subheader class="form-label px-0"> Location </v-subheader>
            <v-text-field
              solo
              single-line
              height="45px"
              v-model="location"
              background-color="#E8E8E8"
              class="form-input rounded-lg"
              placeholder="Location of the rosary"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" class="py-0">
            <v-subheader class="form-label px-0"> Gender* </v-subheader>
            <v-select
              solo
              required
              single-line
              height="45px"
              v-model="gender"
              :items="items"
              :rules="genderRules"
              background-color="#E8E8E8"
              class="form-input rounded-lg"
              placeholder="To receive the file to print"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6" class="py-0">
            <v-subheader class="form-label px-0"> Email </v-subheader>
            <v-text-field
              solo
              single-line
              height="45px"
              v-model="email"
              :rules="email !== '' ? emailRules : []"
              background-color="#E8E8E8"
              class="form-input rounded-lg"
              placeholder="To receive the file to print"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-subheader class="required-fields px-0">
          * Indicates required field
        </v-subheader>

        <v-btn
          dark
          width="100%"
          max-width="141"
          color="#E18666"
          :disabled="!valid"
          @click="postAndGetPdf"
          class="form-btn text-capitalize text-center rounded-lg mt-2"
        >
          Create
        </v-btn>
      </v-form>
    </v-row>
    <v-btn
      plain
      depressed
      max-width="124"
      color="#3E4968"
      :ripple="false"
      @click="openDialog"
      class="example-button-md text-none text-decoration-underline pa-0 pt-2 d-none"
    >
      See the example
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "AddFreeGuide",
  data: (vm) => ({
    fullPage: true,
    isLoading: false,

    valid: true,

    firstname: "",
    firstNameRules: [
      (v) => !!v || "First Name is required",
      (v) =>
        (v && v.length <= 30) || "First Name must be less than 10 characters",
    ],

    lastname: "",
    lastNameRules: [
      (v) => !!v || "Last Name is required",
      (v) =>
        (v && v.length <= 30) || "Last Name must be less than 10 characters",
    ],

    date: {
      day: "",
      month: "",
      year: "",
      hour: "",
      minute: "",
    },
    datetime: null,
    textFieldProps: {
      solo: true,
      readonly: true,
      "single-line": true,
      height: "45px",
      "persistent-hint": "persistent-hint",
      "background-color": "#E8E8E8",
      class: "form-input rounded-lg",
      placeholder: "Date of the rosary",
    },
    dateProps: {
      color: "#3E4968",
      "no-title": true,
    },
    timeProps: {
      color: "#3E4968",
    },

    location: "",

    gender: null,
    genderRules: [(v) => !!v || "Gender is required"],
    items: ["Male", "Female"],

    email: "",
    emailRules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
  }),
  methods: {
    openDialog() {
      this.$emit("openDialog", true);
    },

    formatDate(date) {
      if (!date) return "";

      this.date.day = new Date(date).getDate();
      this.date.month = new Date(date).getMonth() + 1;
      this.date.year = new Date(date).getFullYear();
      this.date.hour = new Date(date).getHours();
      this.date.minute = new Date(date).getMinutes();

      return `${this.date.year}-${this.date.month}-${this.date.day}T${this.date.hour}:${this.date.minute}`;
    },

    base64ToBlob(pdfBase64Str) {
      const pdfString = atob(pdfBase64Str);
      const len = pdfString.length;
      const buffer = new ArrayBuffer(len);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        view[i] = pdfString.charCodeAt(i);
      }
      return view;
    },

    postAndGetPdf() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;

        const formData = new FormData();

        formData.append("firstname", this.firstname);
        formData.append("lastname", this.lastname);
        formData.append("gender", this.gender);
        formData.append("email", this.email);
        formData.append("location", this.location);
        formData.append("date", this.formatDate(this.datetime));

        const queryUrl = "https://funeralrosary.org/api/createform";
        const requestOptions = {
          method: "POST",
          body: formData,
          redirect: "follow",
        };

        fetch(queryUrl, requestOptions)
          .then(async (res) => {
            const pdfName = res.headers.get("filename") || "error.pdf";
            const pdfBlob = await res.blob();
            const pdfBase64Str = await pdfBlob.text();

            return {
              filename: pdfName,
              blob: pdfBlob,
              pdfBase64: pdfBase64Str,
            };
          })
          .then((resObj) => {
            const byteArr = this.base64ToBlob(resObj.pdfBase64);

            // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
            //const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
            const newBlob = new Blob([byteArr], { type: "application/pdf" });

            // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
            } else {
              // For other browsers: create a link pointing to the ObjectURL containing the blob.
              const objUrl = window.URL.createObjectURL(newBlob);

              const link = document.createElement("a");
              link.href = objUrl;
              link.download = resObj.filename;
              link.click();

              // For Firefox it is necessary to delay revoking the ObjectURL.
              setTimeout(() => {
                window.URL.revokeObjectURL(objUrl);
              }, 250);
            }
            this.isLoading = false;
          })
          .catch((error) => console.log("error", error));
      }
    },

    initialiseApi() {
      const healthChkUrl = "https://funeralrosary.org/api/healthcheck";
      const requestOptions = {
        method: "GET",
      };
      fetch(healthChkUrl, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log("API initialised:- ", data);
        })
        .catch((error) => console.log("API not healthy", error));
    },
  },
  beforeMount() {
    this.initialiseApi();
  },
};
</script>
