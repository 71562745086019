<template>
  <v-app>
    <left-bar @toggleSidebar="toggleSidebar" ref="leftBarComponent"/>
    <v-main
      id="main"
      :class="{isFullScreen : isFullScreen}"
    >
      <Header
          @openSidebar="toggleSidebar"
          ref="headerComponent"
      ></Header>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Header from "./components/layout/Header";
import LeftBar from "./components/layout/LeftBar";

export default {
  name: 'App',
  components: {
    Header,
    LeftBar
  },
  data: () => ({
    active: 'active',
    isFullScreen: false,
  }),
  computed: {
  },
  methods: {
    toggleSidebar(value) {
      this.isFullScreen = !this.isFullScreen;
      this.$refs.leftBarComponent.toggle(value)
    },
  }
};
</script>

<style>

</style>
