<template>
  <div id="contact" class="content" ref="contactComponent">
    <v-container fluid class="pa-0 d-flex page-container">
      <v-col>
        <v-layout fill-height class="px-2 px-md-6 px-lg-13">
          <v-col cols="12" md="10">
            <v-row class="ma-0">
              <div class="contact-title page-title">Contact</div>
            </v-row>

            <v-row class="ma-0">
              <div class="contact-subtitle page-subtitle">with us</div>
            </v-row>

            <v-row class="ma-0">
              <v-form id="form" ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <v-subheader class="form-label px-0">
                      First Name*
                    </v-subheader>
                    <v-text-field
                      solo
                      required
                      height="50px"
                      v-model="firstname"
                      :rules="firstNameRules"
                      background-color="#E8E8E8"
                      class="form-input rounded-lg"
                      placeholder="ex: John "
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <v-subheader class="form-label px-0">
                      Last Name*
                    </v-subheader>
                    <v-text-field
                      solo
                      required
                      single-line
                      height="50px"
                      v-model="lastname"
                      :rules="lastNameRules"
                      background-color="#E8E8E8"
                      class="form-input rounded-lg"
                      placeholder="ex: Smith "
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <v-subheader class="form-label px-0"> E-Mail* </v-subheader>
                    <v-text-field
                      solo
                      required
                      single-line
                      height="50px"
                      v-model="email"
                      :rules="emailRules"
                      background-color="#E8E8E8"
                      class="form-input rounded-lg"
                      placeholder="ex: info@domain.com"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <v-subheader class="form-label px-0"> Tel. </v-subheader>
                    <v-text-field
                      solo
                      single-line
                      height="50px"
                      v-model="tel"
                      :rules="tel !== '' ? telRules : []"
                      background-color="#E8E8E8"
                      class="form-input rounded-lg"
                      placeholder="+1 777 123-1234"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-subheader class="form-label px-0">
                      Message*
                    </v-subheader>
                    <v-textarea
                      solo
                      no-resize
                      single-line
                      height="205px"
                      v-model="message"
                      :rules="messageRules"
                      background-color="#E8E8E8"
                      class="form-input rounded-lg"
                      placeholder="Your Message here..."
                    ></v-textarea>
                  </v-col>
                </v-row>

                <vue-recaptcha
                    ref="recaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    :sitekey="siteKey"
                ></vue-recaptcha>

                <v-btn
                  dark
                  height="63px"
                  width="100%"
                  color="#E18666"
                  :disabled="!valid || !verifyCaptcha"
                  @click="sendEmail"
                  class="form-btn text-capitalize text-center rounded-lg mt-2"
                >
                  Send

                  <v-img
                    max-width="20px"
                    class="mx-1"
                    src="@/assets/Images/icons/send.svg"
                  ></v-img>
                </v-btn>
              </v-form>
            </v-row>
          </v-col>
        </v-layout>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: "Contact",
  components: {
    VueRecaptcha
  },
  data: (vm) => ({
    valid: true,

    firstname: "",
    firstNameRules: [
      (v) => !!v || "First Name is required",
      (v) =>
        (v && v.length <= 25) || "First Name must be less than 25 characters",
    ],

    lastname: "",
    lastNameRules: [
      (v) => !!v || "Last Name is required",
      (v) =>
        (v && v.length <= 25) || "Last Name must be less than 25 characters",
    ],

    email: "",
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],

    tel: "",
    telRules: [
      (v) =>
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(v) ||
        "Tel must be valid",
    ],

    message: "",
    messageRules: [
      (v) => !!v || "Message is required",
      (v) => (v && v.length <= 1000) || "Message must be valid",
    ],

    captchaToken : '',
    verifyCaptcha: false,
    siteKey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
  }),
  computed: {
    form() {
      return {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        tel: this.tel,
        message: this.message,
      };
    },
  },
  methods: {
    openDialog() {
      this.$emit("openDialog", true);
    },
    onVerify: function(response) {
      this.verifyCaptcha = true;
      this.captchaToken = response;
    },
    onExpired() {
      console.log('Expired')
    },
    sendEmail() {
      if (this.$refs.form.validate() && this.verifyCaptcha) {
        this.isLoading = true;

        console.log(this.captchaToken);

        // this.$recaptchaLoaded();
        //
        // const token = this.$recaptcha("login");

        ///
        const formData = new FormData();
        formData.append("firstname", this.firstname);
        formData.append("lastname", this.lastname);
        formData.append("tel", this.tel);
        formData.append("email", this.email);
        formData.append("message", this.message);

        //const queryUrl = "http://localhost:8010/api/contactus";
        const queryUrl = "https://funeralrosary.org/api/contactus";
        const requestOptions = {
          method: "POST",
          body: formData,
          redirect: "follow",
        };
        fetch(queryUrl, requestOptions)
          .then((response) => {
            console.log("Email sent successful!");
            this.$router.push("thankyou");
          })
          .catch((error) => console.log("API not healthy", error));
        this.isLoading = false;
      }
    },
  },
};
</script>
